<template>
  <div class="bg-darker pt-5">
    <div class="container">
      <template v-if="currentCategory">
        <h5 class="pt-5 pb-2 text-primary">
          <strong>{{ currentCategory.name }}</strong>
        </h5>
        <div class="row row-grid ">
          <div
            class="col-lg-3 col-md-4"
            v-for="(item, i) in currentCategory.programs"
            v-bind:key="i"
          >
            <router-link :to="'/program/' + item.id" v-lazy-container>
              <img
                class="mw-100 br-5 rounded"
                :data-src="item.img_poster"
                :data-loading="require('@/assets/img/poster_placeholder.jpg')"
                :data-error="require('@/assets/img/poster_placeholder.jpg')"
              />
            </router-link>
            <p class="small bold lh-140 text-white">{{ item.title }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import findLastIndex from "lodash/findLastIndex";
export default {
  name: "CategoryDetail",
  data() {
    return {
      id: 0,
    };
  },
  props: {
    categoryId: Number,
  },
  computed: {
    ...mapState(["category"]),
    currentCategory() {
      return this.getCategory(this.id);
    },
  },
  updated() {
    // console.log("getCategory", this.getCategory(this.id));
  },
  methods: {
    getCategory(id) {
      let categoryIndex = findLastIndex(this.category, item => item.id == id);
      // console.log(categoryIndex);
      return categoryIndex > 0 ? this.category[categoryIndex] : null;
    },
  },
  created() {
    this.$store.dispatch("fetchCategory", this.categoryId).then(() => {
      this.id = this.categoryId;
      // console.log(
      //   "fetched",
      //   this.id,
      //   this.category[this.id],
      //   this.getCategory(this.id)
      // );
    });
  },
};
</script>

<style scoped></style>
